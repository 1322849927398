import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: "Gotham-Black";
    src: url("https://cpxfonts.cc/Gotham-Black.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Gotham-Light";
    src: url("https://cpxfonts.cc/Gotham-Ligth.otf") format("opentype");
  }

  @font-face {
    font-family: "Gotham-Medium";
    src: url("https://cpxfonts.cc/Gotham-Medium.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Gotham-Thin";
    src: url("https://cpxfonts.cc/Gotham-Thin.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Gotham-Ultra";
    src: url("https://cpxfonts.cc/Gotham-Ultra.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`
export default GlobalStyle;