import styled from 'styled-components';

// Assets
import headerImg from "./assets/images/header.svg";
import youtubeImg from "./assets/images/youtube.svg";
import whatsappImg from "./assets/images/whatsapp.svg";
import appImg from "./assets/images/app.svg";

const Main = styled.div`
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/1b6de7d7-1be3-48b6-3dd7-a837de2cc500/public);
  background-size: cover;
  padding: 30px 15px;
`;

const Logo = styled.img`
  display: block;
  padding: 10px 0px;
  margin: auto;

  @media screen and (min-width: 768px){
      padding: 40px 0px;
  }
`;

const Container = styled.div`
  display: flex;
  color: white;
  background: linear-gradient(to right, rgba(83, 123, 153, 0.49) 100%, #8BCDFF 100%);
  border: 1.5px solid #8BCDFF;
  box-shadow: rgba(83, 123, 153, 0.49) 0px 0px 10px, rgba(8, 189, 255, 0.5) 0px 0px 20px;  max-width: 100%;
  border-radius: 8px;
  margin: 30px auto;
  padding: 2px 5px;
  align-items: flex-end;

  h1{
    font-family: "Gotham-Medium";
    font-size: 16px;
    text-align: center;
    margin: 0px;

    span{
      display: block;
      font-size: 25px;
      font-family: "Gotham-Black";

    }
  }

  p{
    margin: 0px;
  }

  @media screen and (min-width: 768px){
    max-width: 360px;
  }

`;

const ContainerImg = styled.img`
    width: 150px;
    scale: 1.1;
`;

const TextDiv = styled.div`
  display: block;
  margin: auto;
`;

const Cass = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -30px;
  z-index: 0;
`;

const GradientButton = styled.button`
  background: linear-gradient(to bottom, #F47100, #C15300);
  font-family: "Gotham-Medium";
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

function App() {
  const imageUrls = [
    'https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/429594b4-7b2f-4472-1250-2259f4bfed00/public',
    'https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/bcaffd23-2eb3-4d8e-d018-b06e35512c00/public',
    youtubeImg,
    whatsappImg,
    appImg
  ]

  const linkUrls = [
    'https://cassinopix.com/?ref=0a450b95e2b4&src=oeroqtglyxcwgdifemjreifww&utm_source=110657&utm_campaign=bio-cassinopix ',
    'https://t.me/Suportecassinopix_bot',
    'https://www.youtube.com/@CassinoPix',
    'https://whatsapp.com/channel/0029VaiB2rC5Ui2P045TbV3L',
    'https://ajuda.cassinopix.com/aplicativo/',
  ];

  const Copy1 = [
    'ACESSE NOSSA',
    'CANAL OFICIAL NO',
    'INSCREVA-SE NO',
    'SIGA O CANAL DO',
    'BAIXE NOSSO'
  ]

  const Copy2 = [
    'PLATAFORMA',
    'TELEGRAM',
    'YOUTUBE',
    'WHATSAPP',
    'APLICATIVO'
  ]

  return (
    <>
      <Main>
        <Logo src='https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/84e986d4-e2ff-4b43-0e3c-341024d60a00/public' />
        <Cass>
          <img src={headerImg} alt="Cassininho Pix" />
        </Cass>
        <div>
          {[0, 1, 2, 3, 4].map((prev) => (
            <Container key={prev}>
              <div>
                <ContainerImg src={imageUrls[prev]} alt="Plataformas" />
              </div>
              <TextDiv>
                <h1>
                  {Copy1[prev]}
                  <span>
                    {Copy2[prev]}
                  </span>
                </h1>
                <div>
                  <GradientButton onClick={() => window.location.href = linkUrls[prev]}>
                    CLIQUE PARA ACESSAR!
                  </GradientButton>
                </div>
              </TextDiv>
            </Container>
          ))}
        </div>
      </Main>
    </>
  );
}

export default App;
